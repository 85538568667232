import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";

import {
  Wrapper,
  MainWrapper,
  Side,
  OpenWrapper,
  VerticalText,
  SideContent,
  Title,
  Description,
  GoBackArrowWrapper,
} from "./style";
import BackgroundSlider from "../../../../../../common/ImageSlider";
import { fixConjunctions } from "../../../../../../../utils/dropTheWord";
import ArrowLeft from "-!svg-react-loader!../../../../../../../assets/images/arrowLeft.svg";
import ArrowHeadLeft from "-!svg-react-loader!../../../../../../../assets/images/arrowHeadLeft.svg";
import ArrowHeadRight from "-!svg-react-loader!../../../../../../../assets/images/arrowHeadRight.svg";

interface ViewFromOfficeProps {
  transitionOpen: boolean;
  handleClose: () => void;
}

const ViewFromOffice: React.FC<ViewFromOfficeProps> = ({
  transitionOpen,
  handleClose,
}) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  const { t } = useTranslation("career");
  const common = useTranslation("common");
  const data = useStaticQuery(backgroundQuery);

  return (
    <Wrapper open={transitionOpen ? 1 : 0}>
      <GoBackArrowWrapper onClick={handleClose}>
        <ArrowLeft />
      </GoBackArrowWrapper>

      <MainWrapper>
        <BackgroundSlider images={data.allFile.nodes} fullscreen />
      </MainWrapper>
      <Side open={isOpen ? 1 : 0}>
        <OpenWrapper onClick={() => setOpen(true)}>
          <ArrowHeadLeft />
          <VerticalText>
            {fixConjunctions(t(`viewFromOffice_title`))}
          </VerticalText>
        </OpenWrapper>
        <SideContent>
          <Title>
            {fixConjunctions(t(`viewFromOffice_title`))}
            <ArrowHeadRight onClick={() => setOpen(false)} />
          </Title>
          <Description>
            {common.t(`OKE`)}
            <br />
            {common.t(`location`)}
          </Description>
        </SideContent>
      </Side>
    </Wrapper>
  );
};

const backgroundQuery = graphql`
  {
    allFile(filter: { relativeDirectory: { eq: "view" } }) {
      nodes {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  }
`;

export default ViewFromOffice;
