import styled, { css } from "styled-components";

import { device } from "../../../../../../../utils/breakpoints";
import { fadeIn, fadeOut } from "../../../../../../../utils/animations";

interface WrapperProps {
  open?: number;
}

export const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  color: #ffffff;
  background: #151515;
  z-index: 2;
  animation: ${(props: WrapperProps) =>
    props.open
      ? css`
          ${fadeIn} 300ms linear
        `
      : css`
          ${fadeOut} 300ms linear
        `};
  animation-fill-mode: forwards;

  svg {
    fill: #ffffff;
  }
`;

export const MainWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

interface SideProps {
  open?: number;
}

export const Side = styled.div`
  position: absolute;
  width: calc(100% + 90px);
  min-height: 24%;
  top: auto;
  left: -90px;
  bottom: 0;
  display: flex;
  flex-direction: row;
  background: #013cff;
  transform: ${(props: SideProps) =>
    props.open ? "translateX(0);" : "translateX(100%) translateX(-90px);"};
  transition: transform 0.6s;
`;

export const OpenWrapper = styled.div`
  width: 90px;
  padding: 44px 28px 24px 28px;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #013cff;
  border-radius: 8px 0 0 0;
`;

export const VerticalText = styled.div`
  margin-top: 32px;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  white-space: pre;
  font-size: 32px;
  line-height: 34px;
  font-weight: 800;
  color: #ffffff;
`;

export const SideContent = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 48px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Title = styled.div`
  margin: 0;
  margin-bottom: 24px;
  font-size: 32px;
  line-height: 34px;
  font-weight: 800;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Description = styled.div`
  grid-column: 5 / span 4;
  grid-row: 5 / span 3;
  margin: 0 auto;
  font-size: 0.6rem;
  white-space: pre-line;
  color: #ffffff;

  @media ${device.mobile}, ${device.tablet}, ${device.tabletLandscape} {
    font-size: 16px;
    line-height: 24px;
    margin: 0;
  } ;
`;

export const GoBackArrowWrapper = styled.div`
  position: absolute;
  top: 24px;
  left: 24px;
  z-index: 1;

  svg > * {
    fill: #151515 !important;
  }
`;
