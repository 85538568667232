import styled, { css } from "styled-components";

import { device } from "../../../../../../utils/breakpoints";
import { fadeIn, fadeOut } from "../../../../../../utils/animations";
import {
  H2_REGULAR,
  H4,
  SUBTITLE_3_MOBILE,
  TEXT_BODY_2,
} from "../../../../../../assets/styles/typography";

interface PageProps {
  animation?: string;
}

export const PageWrapper = styled.div`
  position: relative;
  flex: 1;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  padding: 112px 24px 32px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #151515;
  overflow-x: hidden;
  animation: ${(props: PageProps) =>
    props.animation
      ? css`
          ${fadeOut} 300ms linear
        `
      : css`
          ${fadeIn} 300ms linear
        `};
  animation-fill-mode: forwards;
`;

export const Title = styled.h2`
  ${H4}
  width: 100%;
  margin: 0;
  color: #ffffff;
  white-space: pre-line;
`;

export const Number = styled.h2`
  ${H2_REGULAR}
  width: 100%;
  margin: 0;
  color: #ffffff;
  font-weight: 400;
`;

export const DescriptionWrapper = styled.div`
  position: relative;
  margin: 0;
  flex: 5;
`;

export const Description = styled.div`
  ${TEXT_BODY_2}
  width: 96%;
  color: #ffffff;
  white-space: pre-line;
`;

export const DescriptionItem = styled.div`
  margin-top: 10px;

  @media ${device.mobile} {
    margin-top: 0;
  }
`;

export const HorizontalDivider = styled.hr`
  margin: 16px 0;
  display: block;
  width: 80px;
  height: 5px;
  background: #ffffff;
`;

interface IconsProps {
  currentIndex: number;
}

export const BackgroundIcons = styled.div`
  flex: 3;
  margin: 48px 0 48px 26%;
  width: 100%;
  white-space: nowrap;
  transform: translate3d(
    ${(props: IconsProps) => props.currentIndex * -32}%,
    0,
    0
  );
  transition: 0.3s ease-in-out;
`;

export const BlackBorderLeft = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 12px;
  height: 100%;
  background: #151515;
  z-index: 1;
`;

export const BlackBorderRight = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 12px;
  height: 100%;
  background: #151515;
  z-index: 1;
`;

interface IconProps {
  active?: number;
}

export const Icon = styled.div`
  width: ${(props: IconProps) => (props.active ? "32%" : "16%")};
  display: inline-block;
  margin: auto 8%;
  transition: 0.3s ease-in-out;

  svg {
    width: 100%;
    height: 22vh;
    filter: ${(props: IconProps) => (props.active ? "none" : "blur(8px)")};
  }

  &:hover {
    cursor: pointer;
  }
`;

export const ModalLink = styled.div`
  ${SUBTITLE_3_MOBILE}
  text-transform: uppercase;
  color: #013cff;
  white-space: pre-line;
  pointer-events: all;
  cursor: pointer;
  margin-top: 24px;
`;
