import React from "react";

import LocationIcon from "-!svg-react-loader!../../../../../assets/images/whatWeOffer/location.svg";
import MedicalIcon from "-!svg-react-loader!../../../../../assets/images/whatWeOffer/medical.svg";
import ProgressIcon from "-!svg-react-loader!../../../../../assets/images/whatWeOffer/progress.svg";
import ProfitIcon from "-!svg-react-loader!../../../../../assets/images/whatWeOffer/profit.svg";
import FlexibilityIcon from "-!svg-react-loader!../../../../../assets/images/whatWeOffer/flexibility.svg";
import StudentIcon from "-!svg-react-loader!../../../../../assets/images/whatWeOffer/student.svg";
import SportIcon from "-!svg-react-loader!../../../../../assets/images/whatWeOffer/sport.svg";
import TimeTogetherIcon from "-!svg-react-loader!../../../../../assets/images/whatWeOffer/timeTogether.svg";

export const transitionTimeoutMS = 128;
export const nextPageKeys = ["ArrowDown", "PageDown"];
export const previousPageKeys = ["ArrowUp", "PageUp"];
export const icons = {
  location: <LocationIcon />,
  medical: <MedicalIcon />,
  progress: <ProgressIcon />,
  profit: <ProfitIcon />,
  flexibility: <FlexibilityIcon />,
  student: <StudentIcon />,
  sports: <SportIcon />,
  timetogether: <TimeTogetherIcon />
};

export interface DataAttributes {
  name: "location" | "medical" | "progress" | "profit" | "flexibility" | 
    "student" | "sports" | "timetogether";
  title: string;
  description: string;
  optionalLink?: string;
};