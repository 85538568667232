import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  PageWrapper,
  Title,
  Number,
  DescriptionWrapper,
  Description,
  DescriptionItem,
  HorizontalDivider,
  BackgroundIcons,
  BlackBorderLeft,
  BlackBorderRight,
  Icon,
  ModalLink,
} from "./style";
import { icons, DataAttributes } from "../utils";
import ViewFromOffice from "../ViewFromOffice/Mobile";
import Modal from "../../../../../common/Modal";
import { PageChange } from "../../../../../common/Scroll";
import { numberToFixedLength } from "../../../../../../utils/common";
import { fixConjunctions } from "../../../../../../utils/dropTheWord";
import { PageTitle } from "../../../../../common/PageTitle";

interface WhatWeOfferProps {
  pageChange: PageChange;
  setOpen: (value: boolean) => void;
  goToPage?: (newPage: number) => void;
  blockScroll?: (block: boolean) => void;
}

const WhatWeOfferMobile: React.FC<WhatWeOfferProps> = ({
  pageChange,
  setOpen,
  goToPage,
  blockScroll,
}) => {
  const [page, setPage] = useState<number>(0);
  const [scrollStart, setScrollStart] = useState<number>(0);
  const [lastScroll, setLastScroll] = useState<number>(0);
  const [transitionOpen, setTransitionOpen] = useState<boolean>(false);
  const [openModal, setModalOpen] = useState<boolean>(false);

  const { t } = useTranslation("career");
  const data = ((t(`whatWeOffer_data`) as unknown) as DataAttributes[]).map(
    item => {
      const optionalLink =
        item.optionalLink && item.name === "location" ? (
          <ModalLink
            onClick={() => {
              setTransitionOpen(true);
              setOpen(true);
              setModalOpen(true);
            }}
          >
            {fixConjunctions(item.optionalLink)}
          </ModalLink>
        ) : undefined;

      return {
        ...item,
        icon: icons[item.name],
        optionalLink,
      };
    }
  );
  const currentData = data[page];
  const nOfPages = data && data.length - 1;

  useEffect(() => {
    const handleTouchStart = (e: TouchEvent) => {
      setScrollStart(e.changedTouches[0].clientY);
    };

    const handleTouchScroll = (e: TouchEvent) => {
      const touchMove = e.changedTouches[0].clientY - scrollStart;
      const timeNow = Date.now();
      const timeFromLastScroll = timeNow - lastScroll;
      const minScrollWheelInterval = 64;

      if (timeFromLastScroll >= minScrollWheelInterval) {
        if (touchMove < 0 && page !== nOfPages) {
          page === 0 && blockScroll && blockScroll(true);
          setPage(page + 1);
        } else if (touchMove > 0 && page > 0) {
          page === nOfPages && blockScroll && blockScroll(true);
          setPage(page - 1);
        } else if (page === 0 || page === nOfPages) {
          blockScroll && blockScroll(false);
        }
      }

      setLastScroll(timeNow);
    };

    window.addEventListener("touchstart", handleTouchStart);
    window.addEventListener("touchmove", handleTouchScroll);

    return () => {
      window.removeEventListener("touchstart", handleTouchStart);
      window.removeEventListener("touchmove", handleTouchScroll);
    };
  }, [page, scrollStart, lastScroll]);

  useEffect(() => {
    blockScroll && blockScroll(true);

    return () => {
      blockScroll && blockScroll(false);
    };
  }, []);

  const handleClick = (index: number) => {
    if (index !== page) {
      setPage(index);
    }
  };

  const handleCloseModal = () => {
    setTransitionOpen(false);
    setTimeout(() => {
      setOpen(false);
      setModalOpen(false);
    }, 600);
  };

  return (
    <>
      <PageTitle subtitle={fixConjunctions(t(`whatWeOffer_title`))} />
      <PageWrapper animation={pageChange}>
        <div>
          <Title>{fixConjunctions(currentData.title)}</Title>
        </div>
        <BackgroundIcons currentIndex={page}>
          {data.map((icon, index) => (
            <Icon
              key={index}
              active={page === index ? 1 : 0}
              onClick={() => handleClick(index)}
            >
              {icon.icon}
            </Icon>
          ))}
        </BackgroundIcons>
        <BlackBorderLeft />
        <BlackBorderRight />
        <DescriptionWrapper>
          <HorizontalDivider />
          <Description>
            {Object.values(currentData.description).map(el => (
              <DescriptionItem>
                {"\u2022"} {el}
              </DescriptionItem>
            ))}
          </Description>
          {currentData.optionalLink || undefined}
        </DescriptionWrapper>

        <Modal
          open={openModal}
          handleClose={handleCloseModal}
          style={{ background: "none", width: "100%" }}
        >
          <ViewFromOffice
            transitionOpen={transitionOpen}
            handleClose={handleCloseModal}
          />
        </Modal>
      </PageWrapper>
    </>
  );
};

export default WhatWeOfferMobile;
